import { extendBlockerBodyStylesheet } from "@devowl-wp/web-cookie-banner";
import { useState } from "react";
import { useBlockerStylesheet, useA11yId, useBlockerMissingServices } from "../../..";
import { Cookie, useBlocker, useCommonTeachings } from "../../..";
import { BodyDescription, Button } from "../../common";
const BlockerBody = () => {
  const {
    Container,
    CookieScroll,
    a11yIds: {
      firstButton
    }
  } = useBlockerStylesheet().extend(...extendBlockerBodyStylesheet);
  const blocker = useBlocker();
  const a11yCookieList = useA11yId();
  const [areCookiesVisible, setCookiesVisible] = useState(false);
  const {
    texts: {
      blockerLoadButton,
      blockerLinkShowMissing,
      blockerAcceptInfo
    },
    onUnblock,
    productionNotice,
    isConsentRecord,
    i18n: {
      close
    }
  } = blocker;
  const missing = useBlockerMissingServices();
  const {
    description,
    teachings
  } = useCommonTeachings({
    services: missing,
    // Not needed in blocker, as there is already a link with "Show all services which I need to consent to"
    disableListServicesNotice: true,
    disableTcfPurposes: true
  });
  return h(Container, null, h("div", null, h(BodyDescription, {
    description: description,
    teachings: [...teachings, blockerAcceptInfo],
    nl2br: true
  }), h(Button, {
    id: firstButton,
    type: "acceptIndividual",
    onClick: () => setCookiesVisible(!areCookiesVisible),
    forceShow: true,
    busyOnClick: false,
    "aria-expanded": areCookiesVisible,
    "aria-controls": a11yCookieList
  }, areCookiesVisible ? close : blockerLinkShowMissing), h(CookieScroll, null, areCookiesVisible && missing.map(cookie => h(Cookie, {
    key: cookie.id,
    cookie: cookie,
    checked: true,
    disabled: true
  }))), h(Button, {
    type: "acceptAll",
    onClick: e => onUnblock(e),
    forceShow: true,
    sticky: true,
    busyOnClick: !isConsentRecord
  }, blockerLoadButton), productionNotice));
};
export { BlockerBody };