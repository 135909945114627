import { useMemo, Suspense } from "react";
import { getOtherOptionsFromWindow } from "../utils";
import { applyConsent, getCookieConsentManager } from "../others";
import { HTML_ATTRIBUTE_COOKIE_IDS } from "@devowl-wp/headless-content-unblocker";
import { useStateContextCallbacks, useBlockerStateContextCallbacks, useBlockerProvider } from "@devowl-wp/react-cookie-banner";
import { Recorder } from "@devowl-wp/web-html-element-interaction-recorder";
import { BannerProductionNotice } from ".";
import { usePlainCss, yieldLazyLoad } from "@devowl-wp/react-utils";
const Blocker = yieldLazyLoad(import( /* webpackMode: "eager" */"@devowl-wp/react-cookie-banner/src/components/blocker/blocker").then(_ref => {
  let {
    Blocker
  } = _ref;
  return Blocker;
}));

/**
 * Simply connects a context store to the blocker itself. On the website itself
 * it should not rely
 */
const WebsiteBlocker = _ref2 => {
  let {
    container,
    blockedNode,
    poweredLink,
    blocker,
    paintMode,
    setVisualAsLastClickedVisual
  } = _ref2;
  const {
    customizeValuesBanner,
    territorialLegalBasis,
    consentForwardingExternalHosts,
    essentialGroup,
    isDataProcessingInUnsafeCountries,
    dataProcessingInUnsafeCountriesSafeCountries,
    iso3166OneAlpha2,
    isAgeNotice,
    ageNoticeAgeLimit,
    isListServicesNotice,
    isGcm,
    isGcmListPurposes,
    groups,
    bannerLinks,
    websiteOperator,
    bannerDesignVersion,
    bannerI18n,
    isPro,
    isLicensed,
    isDevLicense,
    affiliate,
    isCurrentlyInTranslationEditorPreview
  } = getOtherOptionsFromWindow();
  const manager = getCookieConsentManager();
  const userDecision = manager.getUserDecision();
  const recorder = useMemo(() => new Recorder(container), []);
  const modifiers = useStateContextCallbacks();
  const {
    onUnblock
  } = useBlockerStateContextCallbacks(manager.getOptions());

  // Context values preparation
  const [BlockerContextProvider, blockerContextValue] = useBlockerProvider({
    recorder,
    container,
    blockedNode,
    ...customizeValuesBanner,
    productionNotice: h(BannerProductionNotice, {
      isPro: isPro,
      isLicensed: isLicensed,
      isDevLicense: isDevLicense,
      i18n: bannerI18n
    }),
    territorialLegalBasis,
    consentForwardingExternalHosts,
    paintMode,
    groups,
    essentialGroup,
    links: bannerLinks,
    websiteOperator,
    poweredLink,
    isDataProcessingInUnsafeCountries,
    dataProcessingInUnsafeCountriesSafeCountries,
    iso3166OneAlpha2,
    isAgeNotice,
    ageNoticeAgeLimit,
    isListServicesNotice,
    isGcm,
    isGcmListPurposes,
    gcmConsent: [],
    blocker,
    designVersion: bannerDesignVersion,
    i18n: bannerI18n,
    keepVariablesInTexts: isCurrentlyInTranslationEditorPreview,
    affiliate,
    consent: {
      ...(userDecision === false ? {} : userDecision.consent)
    },
    onApplyConsent: applyConsent
  }, {
    ...modifiers,
    onUnblock: (contextValue, event) => {
      onUnblock(contextValue, event);
      setVisualAsLastClickedVisual(event);
    }
  });
  usePlainCss([`.elementor-background-overlay ~ [${HTML_ATTRIBUTE_COOKIE_IDS}] { z-index: 99; }`].join(""));
  return h(BlockerContextProvider, {
    value: blockerContextValue
  }, h(Suspense, {
    fallback: null
  }, h(Blocker, null)));
};
export { WebsiteBlocker };