import { useMemo } from "react";
import { useBlocker } from "../../contexts";
/**
 * Read all missing services required for this content blocker.
 */
function useBlockerMissingServices() {
  const {
    blocker: {
      services
    },
    consent,
    groups
  } = useBlocker();

  // Calculate missing cookies
  return useMemo(() => {
    const missing = [];
    const allConsentCookies = [];
    for (const cookies of Object.values(consent)) {
      allConsentCookies.push(...cookies);
    }
    for (const {
      items
    } of groups) {
      for (const cookie of items) {
        // Cookie is needed for this blocker? Yes, check if already consent...
        if (services.indexOf(cookie.id) > -1 && allConsentCookies.indexOf(cookie.id) === -1) {
          missing.push(cookie);
        }
      }
    }
    return missing;
  }, [groups, services, consent]);
}
export { useBlockerMissingServices };